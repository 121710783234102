import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', 
    redirect: { name: 'concept' }
  },
  
  {
    path: '*',
    redirect: { name: 'concept' }
  },

  {
    path: '/startsharing',
    name: 'startsharing',
    component: () => import(/* webpackChunkName: "quilt" */ '@/views/Quilt.vue')
  },

  {
    path: '/quilt',
    name: 'quilt',
    component: () => import(/* webpackChunkName: "quilt" */ '@/views/Quilt.vue'),

    children: [{
      path: ':assetId',
      component: () => import(/* webpackChunkName: "quilt" */ '@/components/AssetView.vue')
    }]

  },

  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "map" */ '@/views/Map.vue'),

    children: [{
      path: ':assetId',
      component: () => import(/* webpackChunkName: "map" */ '@/components/AssetView.vue')
    }]
  },

  {
    path: '/concept',
    name: 'concept',
    component: () => import(/* webpackChunkName: "concept" */ '@/views/Concept.vue')
  },

  {
    path: '/groovalutionaryu',
    name: 'groovalutionaryu',
    component: () => import(/* webpackChunkName: "concept" */ '@/views/GroovalutionaryU.vue')
  },

  {
    path: '/doodlefordollars',
    name: 'doodlefordollars',
    component: () => import(/* webpackChunkName: "concept" */ '@/views/DoodleForDollars.vue')
  },

  {
    path: '/entertainmentforchange/:charityId',
    name: 'entertainmentforchange',
    component: () => import(/* webpackChunkName: "concept" */ '@/views/EntertainmentForChange.vue'),
  }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior() {
  //   return {x: 0, y: 0}
  // }
})
